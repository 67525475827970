define("bp-ember-components/components/data-grid/fractal-object/index", ["exports", "fractal-page-object", "bp-ember-components/components/fractal-objects/ember-table", "@ember/test-helpers"], function (_exports, _fractalPageObject, _emberTable, _testHelpers) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.DataGrid = void 0;
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  class DataGridPO extends _fractalPageObject.PageObject {
    constructor(...args) {
      super(...args);
      _defineProperty(this, "filterInput", (0, _fractalPageObject.selector)('[data-test-fulltext-filter]'));
      _defineProperty(this, "table", _emberTable.EmberTable);
    }
    async filterItems(needle) {
      await (0, _testHelpers.fillIn)(this.filterInput.element, needle);
    }
  }
  const DataGrid = _exports.DataGrid = (0, _fractalPageObject.selector)('[data-test-data-grid]', DataGridPO);
});