define("@frontile/changeset-form/components/changeset-form/fields/textarea", ["exports", "@ember/component", "@frontile/changeset-form/components/changeset-form/fields/base", "@ember/object", "@ember/template-factory"], function (_exports, _component, _base, _object, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _class;
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <FormTextarea
    {{on "blur" this.validate}}
  
    @value={{this.value}}
    @onInput={{this.handleInput}}
    @errors={{this.errors}}
  
    @hasSubmitted={{@hasSubmitted}}
    @type={{@type}}
    @label={{@label}}
    @hint={{@hint}}
    @hasError={{@hasError}}
    @showError={{@showError}}
    @containerClass={{@containerClass}}
    @size={{@size}}
    @onChange={{@onChange}}
    @onFocusIn={{@onFocusIn}}
    @onFocusOut={{@onFocusOut}}
  
    ...attributes
  >
    {{yield}}
  </FormTextarea>
  
  */
  {
    "id": "K5lbtKFF",
    "block": "[[[8,[39,0],[[17,1],[4,[38,1],[\"blur\",[30,0,[\"validate\"]]],null]],[[\"@value\",\"@onInput\",\"@errors\",\"@hasSubmitted\",\"@type\",\"@label\",\"@hint\",\"@hasError\",\"@showError\",\"@containerClass\",\"@size\",\"@onChange\",\"@onFocusIn\",\"@onFocusOut\"],[[30,0,[\"value\"]],[30,0,[\"handleInput\"]],[30,0,[\"errors\"]],[30,2],[30,3],[30,4],[30,5],[30,6],[30,7],[30,8],[30,9],[30,10],[30,11],[30,12]]],[[\"default\"],[[[[1,\"\\n  \"],[18,13,null],[1,\"\\n\"]],[]]]]],[1,\"\\n\"]],[\"&attrs\",\"@hasSubmitted\",\"@type\",\"@label\",\"@hint\",\"@hasError\",\"@showError\",\"@containerClass\",\"@size\",\"@onChange\",\"@onFocusIn\",\"@onFocusOut\",\"&default\"],false,[\"form-textarea\",\"on\",\"yield\"]]",
    "moduleName": "@frontile/changeset-form/components/changeset-form/fields/textarea.hbs",
    "isStrictMode": false
  });
  let ChangesetFormFieldsTextarea = _exports.default = (_class = class ChangesetFormFieldsTextarea extends _base.default {
    handleInput(value, event) {
      this.args.changeset.set(this.args.fieldName, value);
      if (typeof this.args.onInput === 'function') {
        this.args.onInput(value, event);
      }
    }
  }, _applyDecoratedDescriptor(_class.prototype, "handleInput", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "handleInput"), _class.prototype), _class);
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, ChangesetFormFieldsTextarea);
});