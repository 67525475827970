define("@frontile/forms/components/form-field/radio", ["exports", "@ember/component", "@glimmer/component", "@ember/object", "@ember/template-factory"], function (_exports, _component, _component2, _object, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _class;
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <input
    {{on "change" this.handleChange}}
    id={{@id}}
    name={{@name}}
    value={{@value}}
    checked={{this.isChecked}}
    type="radio"
    class={{use-frontile-class "form-field-radio" @size}}
    data-test-id="form-field-radio"
    ...attributes
  >
  
  */
  {
    "id": "boWhU8Do",
    "block": "[[[11,\"input\"],[16,1,[30,1]],[16,3,[30,2]],[16,2,[30,3]],[16,\"checked\",[30,0,[\"isChecked\"]]],[24,4,\"radio\"],[16,0,[28,[37,0],[\"form-field-radio\",[30,4]],null]],[17,5],[4,[38,1],[\"change\",[30,0,[\"handleChange\"]]],null],[12],[13],[1,\"\\n\"]],[\"@id\",\"@name\",\"@value\",\"@size\",\"&attrs\"],false,[\"use-frontile-class\",\"on\"]]",
    "moduleName": "@frontile/forms/components/form-field/radio.hbs",
    "isStrictMode": false
  });
  let FormFieldRadio = _exports.default = (_class = class FormFieldRadio extends _component2.default {
    get isChecked() {
      return this.args.checked === this.args.value;
    }
    handleChange(event) {
      event.preventDefault();
      if (typeof this.args.onChange === 'function') {
        this.args.onChange(this.args.value, event);
      }
    }
  }, _applyDecoratedDescriptor(_class.prototype, "handleChange", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "handleChange"), _class.prototype), _class);
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, FormFieldRadio);
});