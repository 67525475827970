define("bp-ember-components/components/form/select/employee/index", ["exports", "@ember/component", "@ember/template-factory", "@glimmer/component", "@ember/service"], function (_exports, _component, _templateFactory, _component2, _service) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _class, _descriptor;
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <Form::Select
    @placeholder={{t 'visit.employee'}}
    @options={{this.employees.active}}
    @onChange={{@onChange}}
    @renderInPlace={{@renderInPlace}}
    @searchEnabled={{true}}
    @selected={{@selected}}
    @searchField='fullName'
    as |employee|
  >
    <Avatar
      @src={{employee.photoUrl}}
      @alt={{employee.fullName}}
      @size='6'
      class='inline'
    />
    {{employee.fullName}}
  </Form::Select>
  */
  {
    "id": "jnjtisLo",
    "block": "[[[8,[39,0],null,[[\"@placeholder\",\"@options\",\"@onChange\",\"@renderInPlace\",\"@searchEnabled\",\"@selected\",\"@searchField\"],[[28,[37,1],[\"visit.employee\"],null],[30,0,[\"employees\",\"active\"]],[30,1],[30,2],true,[30,3],\"fullName\"]],[[\"default\"],[[[[1,\"\\n  \"],[8,[39,2],[[24,0,\"inline\"]],[[\"@src\",\"@alt\",\"@size\"],[[30,4,[\"photoUrl\"]],[30,4,[\"fullName\"]],\"6\"]],null],[1,\"\\n  \"],[1,[30,4,[\"fullName\"]]],[1,\"\\n\"]],[4]]]]]],[\"@onChange\",\"@renderInPlace\",\"@selected\",\"employee\"],false,[\"form/select\",\"t\",\"avatar\"]]",
    "moduleName": "bp-ember-components/components/form/select/employee/index.hbs",
    "isStrictMode": false
  });
  let FormSelectEmployeeComponent = _exports.default = (_class = class FormSelectEmployeeComponent extends _component2.default {
    constructor(...args) {
      super(...args);
      _initializerDefineProperty(this, "employees", _descriptor, this);
    }
  }, _descriptor = _applyDecoratedDescriptor(_class.prototype, "employees", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _class);
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, FormSelectEmployeeComponent);
});