define("@frontile/changeset-form/components/changeset-form/fields/radio", ["exports", "@ember/component", "@frontile/changeset-form/components/changeset-form/fields/base", "@ember/object", "@ember/template-factory"], function (_exports, _component, _base, _object, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _class;
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <FormRadio
    @onChange={{this.handleChange}}
    @checked={{this.value}}
    @hint={{@hint}}
    @value={{@value}}
    @name={{@name}}
    @containerClass={{@containerClass}}
    @size={{@size}}
    ...attributes
    {{on "blur" this.validate}}
  >
    {{#if (has-block)}}
      {{yield}}
    {{else}}
      {{@label}}
    {{/if}}
  </FormRadio>
  */
  {
    "id": "ZcTDEVaE",
    "block": "[[[8,[39,0],[[17,1],[4,[38,1],[\"blur\",[30,0,[\"validate\"]]],null]],[[\"@onChange\",\"@checked\",\"@hint\",\"@value\",\"@name\",\"@containerClass\",\"@size\"],[[30,0,[\"handleChange\"]],[30,0,[\"value\"]],[30,2],[30,3],[30,4],[30,5],[30,6]]],[[\"default\"],[[[[1,\"\\n\"],[41,[48,[30,8]],[[[1,\"    \"],[18,8,null],[1,\"\\n\"]],[]],[[[1,\"    \"],[1,[30,7]],[1,\"\\n\"]],[]]]],[]]]]]],[\"&attrs\",\"@hint\",\"@value\",\"@name\",\"@containerClass\",\"@size\",\"@label\",\"&default\"],false,[\"form-radio\",\"on\",\"if\",\"has-block\",\"yield\"]]",
    "moduleName": "@frontile/changeset-form/components/changeset-form/fields/radio.hbs",
    "isStrictMode": false
  });
  let ChangesetFormFieldsRadio = _exports.default = (_class = class ChangesetFormFieldsRadio extends _base.default {
    async handleChange(value, event) {
      event.preventDefault();
      this.args.changeset.set(this.args.fieldName, value);
      await this.validate();
      if (typeof this.args.onChange === 'function') {
        this.args.onChange(value, event);
      }
    }
  }, _applyDecoratedDescriptor(_class.prototype, "handleChange", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "handleChange"), _class.prototype), _class);
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, ChangesetFormFieldsRadio);
});