define("@frontile/forms/components/form-field/input", ["exports", "@ember/component", "@glimmer/component", "@ember/object", "@ember/template-factory"], function (_exports, _component, _component2, _object, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _class;
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <input
    {{on "input" this.handleOnInput}}
    {{on "change" this.handleOnChange}}
    id={{@id}}
    value={{@value}}
    type={{this.type}}
    class={{use-frontile-class "form-field-input" @size}}
    data-test-id="form-field-input"
    ...attributes
  >
  
  */
  {
    "id": "yyfC9nNS",
    "block": "[[[11,\"input\"],[16,1,[30,1]],[16,2,[30,2]],[16,4,[30,0,[\"type\"]]],[16,0,[28,[37,0],[\"form-field-input\",[30,3]],null]],[17,4],[4,[38,1],[\"input\",[30,0,[\"handleOnInput\"]]],null],[4,[38,1],[\"change\",[30,0,[\"handleOnChange\"]]],null],[12],[13],[1,\"\\n\"]],[\"@id\",\"@value\",\"@size\",\"&attrs\"],false,[\"use-frontile-class\",\"on\"]]",
    "moduleName": "@frontile/forms/components/form-field/input.hbs",
    "isStrictMode": false
  });
  let FormFieldInput = _exports.default = (_class = class FormFieldInput extends _component2.default {
    get type() {
      if (typeof this.args.type === 'string') {
        return this.args.type;
      }
      return 'text';
    }
    handleOnInput(event) {
      if (typeof this.args.onInput === 'function') {
        this.args.onInput(event.target.value, event);
      }
    }
    handleOnChange(event) {
      if (typeof this.args.onChange === 'function') {
        this.args.onChange(event.target.value, event);
      }
    }
  }, _applyDecoratedDescriptor(_class.prototype, "handleOnInput", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "handleOnInput"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "handleOnChange", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "handleOnChange"), _class.prototype), _class);
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, FormFieldInput);
});