define("@frontile/changeset-form/components/changeset-form/fields/base", ["exports", "@glimmer/component", "@ember/debug", "@ember/object", "@ember/runloop"], function (_exports, _component, _debug, _object, _runloop) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _class;
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  let ChangesetFormFieldsBase = _exports.default = (_class = class ChangesetFormFieldsBase extends _component.default {
    constructor(owner, args) {
      super(owner, args);
      (false && !(typeof this.args.changeset !== 'undefined') && (0, _debug.assert)('<ChangesetForm> fields must receive @changeset', typeof this.args.changeset !== 'undefined'));
      (false && !(typeof this.args.fieldName !== 'undefined') && (0, _debug.assert)('<ChangesetForm> fields must receive @fieldName', typeof this.args.fieldName !== 'undefined'));
    }
    get value() {
      return this.args.changeset.get(this.args.fieldName);
    }
    get errors() {
      if (typeof this.args.errors !== 'undefined') {
        return this.args.errors;
      }
      const fieldErrors = this.args.changeset.errors.filter(error => {
        return error.key === this.args.fieldName;
      });
      return fieldErrors.reduce((errors, error) => {
        if (Array.isArray(error.validation)) {
          return [...errors, ...error.validation];
        } else {
          return [...errors, error.validation];
        }
      }, []);
    }
    async validate() {
      (0, _runloop.later)(this, () => {
        this.args.changeset.validate(this.args.fieldName);
      }, 1);
    }
  }, _applyDecoratedDescriptor(_class.prototype, "validate", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "validate"), _class.prototype), _class);
});