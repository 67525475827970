define("@frontile/changeset-form/components/changeset-form/index", ["exports", "@ember/component", "@glimmer/component", "@glimmer/tracking", "@ember/object", "@ember/debug", "@ember/runloop", "@ember/template-factory"], function (_exports, _component, _component2, _tracking, _object, _debug, _runloop, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _class, _descriptor;
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <form
    ...attributes
    {{on "submit" (fn this.handleSubmit @changeset)}}
    {{on "reset" (fn this.handleReset @changeset)}}
  >
  
    {{yield
      (hash
        Input=(component
          "changeset-form/fields/input"
          changeset=@changeset
          hasSubmitted=this.hasSubmitted
          showError=@alwaysShowErrors
        )
        Textarea=(component
          "changeset-form/fields/textarea"
          changeset=@changeset
          hasSubmitted=this.hasSubmitted
          showError=@alwaysShowErrors
        )
        Select=(component
          "changeset-form/fields/select"
          changeset=@changeset
          hasSubmitted=this.hasSubmitted
          showError=@alwaysShowErrors
        )
        Checkbox=(component
          "changeset-form/fields/checkbox"
          changeset=@changeset
          hasSubmitted=this.hasSubmitted
          showError=@alwaysShowErrors
        )
        CheckboxGroup=(component
          "changeset-form/fields/checkbox-group"
          changeset=@changeset
          hasSubmitted=this.hasSubmitted
          showError=@alwaysShowErrors
        )
        Radio=(component
          "changeset-form/fields/radio"
          changeset=@changeset
          hasSubmitted=this.hasSubmitted
          showError=@alwaysShowErrors
        )
        RadioGroup=(component
          "changeset-form/fields/radio-group"
          changeset=@changeset
          hasSubmitted=this.hasSubmitted
          showError=@alwaysShowErrors
        )
        state=(hash hasSubmitted=this.hasSubmitted)
      )
    }}
  
  </form>
  */
  {
    "id": "ikJlxBMy",
    "block": "[[[11,\"form\"],[17,1],[4,[38,0],[\"submit\",[28,[37,1],[[30,0,[\"handleSubmit\"]],[30,2]],null]],null],[4,[38,0],[\"reset\",[28,[37,1],[[30,0,[\"handleReset\"]],[30,2]],null]],null],[12],[1,\"\\n\\n  \"],[18,4,[[28,[37,3],null,[[\"Input\",\"Textarea\",\"Select\",\"Checkbox\",\"CheckboxGroup\",\"Radio\",\"RadioGroup\",\"state\"],[[50,\"changeset-form/fields/input\",0,null,[[\"changeset\",\"hasSubmitted\",\"showError\"],[[30,2],[30,0,[\"hasSubmitted\"]],[30,3]]]],[50,\"changeset-form/fields/textarea\",0,null,[[\"changeset\",\"hasSubmitted\",\"showError\"],[[30,2],[30,0,[\"hasSubmitted\"]],[30,3]]]],[50,\"changeset-form/fields/select\",0,null,[[\"changeset\",\"hasSubmitted\",\"showError\"],[[30,2],[30,0,[\"hasSubmitted\"]],[30,3]]]],[50,\"changeset-form/fields/checkbox\",0,null,[[\"changeset\",\"hasSubmitted\",\"showError\"],[[30,2],[30,0,[\"hasSubmitted\"]],[30,3]]]],[50,\"changeset-form/fields/checkbox-group\",0,null,[[\"changeset\",\"hasSubmitted\",\"showError\"],[[30,2],[30,0,[\"hasSubmitted\"]],[30,3]]]],[50,\"changeset-form/fields/radio\",0,null,[[\"changeset\",\"hasSubmitted\",\"showError\"],[[30,2],[30,0,[\"hasSubmitted\"]],[30,3]]]],[50,\"changeset-form/fields/radio-group\",0,null,[[\"changeset\",\"hasSubmitted\",\"showError\"],[[30,2],[30,0,[\"hasSubmitted\"]],[30,3]]]],[28,[37,3],null,[[\"hasSubmitted\"],[[30,0,[\"hasSubmitted\"]]]]]]]]]],[1,\"\\n\\n\"],[13]],[\"&attrs\",\"@changeset\",\"@alwaysShowErrors\",\"&default\"],false,[\"on\",\"fn\",\"yield\",\"hash\",\"component\"]]",
    "moduleName": "@frontile/changeset-form/components/changeset-form/index.hbs",
    "isStrictMode": false
  });
  let ChangesetForm = _exports.default = (_class = class ChangesetForm extends _component2.default {
    constructor(owner, args) {
      super(owner, args);
      _initializerDefineProperty(this, "hasSubmitted", _descriptor, this);
      (false && !(this.args.changeset) && (0, _debug.assert)('@changeset must be defined on <ChangesetForm> component', this.args.changeset));
      if (this.args.validateOnInit) {
        (0, _runloop.next)(() => {
          this.args.changeset.validate();
        });
      }
    }
    async handleSubmit(changeset, event) {
      event.preventDefault();
      await changeset.validate();
      this.hasSubmitted = true;
      if (changeset.isInvalid) {
        return;
      }
      let result;
      if (this.args.runExecuteInsteadOfSave) {
        result = changeset.execute();
      } else {
        result = await changeset.save({});
      }
      if (typeof this.args.onSubmit === 'function') {
        this.args.onSubmit(result.data, event);
      }
    }
    handleReset(changeset, event) {
      event.preventDefault();
      this.hasSubmitted = false;
      const {
        data
      } = changeset.rollback();
      if (typeof this.args.onReset === 'function') {
        this.args.onReset(data, event);
      }
    }
  }, _descriptor = _applyDecoratedDescriptor(_class.prototype, "hasSubmitted", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _applyDecoratedDescriptor(_class.prototype, "handleSubmit", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "handleSubmit"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "handleReset", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "handleReset"), _class.prototype), _class);
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, ChangesetForm);
});