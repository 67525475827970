define("bp-ember-components/components/fractal-objects/ember-table/index", ["exports", "fractal-page-object"], function (_exports, _fractalPageObject) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.EmberTable = void 0;
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  const EmberTable = _exports.EmberTable = (0, _fractalPageObject.selector)('[data-test-ember-table]', class extends _fractalPageObject.PageObject {
    constructor(...args) {
      super(...args);
      _defineProperty(this, "head", (0, _fractalPageObject.selector)('thead'));
      _defineProperty(this, "body", (0, _fractalPageObject.selector)('tbody', class extends _fractalPageObject.PageObject {
        constructor(...args) {
          super(...args);
          _defineProperty(this, "rows", (0, _fractalPageObject.selector)('tr', class extends _fractalPageObject.PageObject {
            constructor(...args) {
              super(...args);
              _defineProperty(this, "cells", (0, _fractalPageObject.selector)('td'));
            }
          }));
        }
        get rowCount() {
          return this.rows.length;
        }
      }));
    }
    getCell(row, col) {
      return this.body.rows[row].cells[col];
    }
  });
});