define("@frontile/changeset-form/components/changeset-form/fields/radio-group", ["exports", "@ember/component", "@frontile/changeset-form/components/changeset-form/fields/base", "@ember/object", "@ember/template-factory"], function (_exports, _component, _base, _object, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _class;
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <FormRadioGroup
    @onChange={{this.handleChange}}
    @value={{this.value}}
    @errors={{this.errors}}
  
    @label={{@label}}
    @hint={{@hint}}
    @hasSubmitted={{@hasSubmitted}}
    @hasError={{@hasError}}
    @showError={{@showError}}
    @containerClass={{@containerClass}}
    @size={{@size}}
    @isInline={{@isInline}}
  
    ...attributes
    as |Radio|
  >
    {{yield Radio}}
  </FormRadioGroup>
  
  */
  {
    "id": "9NgEIboZ",
    "block": "[[[8,[39,0],[[17,1]],[[\"@onChange\",\"@value\",\"@errors\",\"@label\",\"@hint\",\"@hasSubmitted\",\"@hasError\",\"@showError\",\"@containerClass\",\"@size\",\"@isInline\"],[[30,0,[\"handleChange\"]],[30,0,[\"value\"]],[30,0,[\"errors\"]],[30,2],[30,3],[30,4],[30,5],[30,6],[30,7],[30,8],[30,9]]],[[\"default\"],[[[[1,\"\\n  \"],[18,11,[[30,10]]],[1,\"\\n\"]],[10]]]]],[1,\"\\n\"]],[\"&attrs\",\"@label\",\"@hint\",\"@hasSubmitted\",\"@hasError\",\"@showError\",\"@containerClass\",\"@size\",\"@isInline\",\"Radio\",\"&default\"],false,[\"form-radio-group\",\"yield\"]]",
    "moduleName": "@frontile/changeset-form/components/changeset-form/fields/radio-group.hbs",
    "isStrictMode": false
  });
  let ChangesetFormFieldsRadioGroup = _exports.default = (_class = class ChangesetFormFieldsRadioGroup extends _base.default {
    async handleChange(value, event) {
      event.preventDefault();
      this.args.changeset.set(this.args.fieldName, value);
      await this.validate();
      if (typeof this.args.onChange === 'function') {
        this.args.onChange(value, event);
      }
    }
  }, _applyDecoratedDescriptor(_class.prototype, "handleChange", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "handleChange"), _class.prototype), _class);
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, ChangesetFormFieldsRadioGroup);
});